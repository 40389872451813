.memePage {
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 100px 600px;
    grid-template-areas: 
    "header header header header"
    "desc desc image image";
}

.memeNameWrapper {
    padding: 20px 20px 0px 20px;
    grid-area: header;
}

.memeEdit {
    cursor: pointer;
    width: 3vh;
    height: 3vh;
}

.memeName {
    font-size: 3em;
    border-bottom: 1px solid;
}

.desc {
    grid-area: desc;
    font-size: 1.5em;
    padding-left: 30px;
    padding-right: 30px;
}

.memeImage {
    grid-area: image;
}

.image {
    max-width: 100%;
}

.edit-buttons{
    display: flex;
    width: 100%;
    justify-content: space-evenly;
}

.submit-edits {
    margin-top: 15px;    
    display: flex;
    width: 30%;
    height: 4.5vh;
    min-height: 40px;
    border: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    transition: 500ms;
    background: linear-gradient(120deg,#69ABED, #EA7A55);
    background-size: 200%;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
}

.submit-edits:hover {
    background-position: right;
}

.cancel-edits {
    margin-top: 15px;    
    display: flex;
    width: 30%;
    height: 4.5vh;
    min-height: 40px;
    border: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    transition: 500ms;
    background: linear-gradient(120deg,#69ABED, #EA7A55);
    background-size: 200%;
    justify-content: center;
    align-items: center;
}

.cancel-edits:hover {
    background-position: right;
}

.edit-input {
    width: 100%;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
}

.edit-input input {
    border: none;
    background: none;
    width: 100%;
    outline: none;
    padding: 0 5px;
    color: #333;
    height: 40px;
}

@media only screen and (max-width: 768px) {
    .memePage {
        grid-template-columns: auto;
        grid-template-rows: .25fr 1fr 2fr;
        grid-template-areas: 
        "header"
        "image"
        "desc";
    }    
}

