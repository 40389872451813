.card-wrapper {
    padding: 10px;
}

.link {
    text-decoration: none;
    color: var(--text-primary);
}

.card-shadow {
    width: 25vh;
    height: 20vh;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    transition: 300ms;
}

.card-shadow:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.card {
    background-color: var(--bg-primary);
    height: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 3fr 1fr;
    grid-template-areas: 
    "image" 
    "card-desc";
}

.card img, .card-loader-wrapper {
    grid-area: image;
    object-fit: cover;
    width: 100%;
    max-height: 100%;
}

.card-loader-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-desc {
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    font-size: 1.7vh;
    grid-area: card-desc;
}