body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-moz-focus-inner {
  border: 0;
}

:root {
  font-size: 16px;
  --text-primary: #696969;
  --text-secondary: #69420D;
  --bg-primary: whitesmoke;
  --bg-secondary: #BEB69D;
  --bg-tertiary: #DADBAE;
  --fa-primary: #69420D;
  --trainsition-speed: 500ms;
  --bg-gradient: linear-gradient(120deg,#69ABED, #EA7A55);
}