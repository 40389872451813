.meme-builder {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.meme-form {
    height: 80vh;
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
    border-radius: 10px;

}

.meme-input {
    margin: 10px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.meme-input input {
    border: none;
    background: none;
    width: 100%;
    height: 2vh;
    outline: none;
    padding: 0 5px;
    color: var(--text-primary);
}

.meme-name-input, .meme-tldr-input {
    border-bottom: 2px solid #adadad;
}

.meme-desc-input textarea {
    margin-top: 0;
    width: 40vw;
    height: 40vh;
}

.meme-submit {
    margin-top: 15px;    
    display: block;
    width: 75%;
    height: 4.5vh;
    min-height: 40px;
    border: none;
    color: #fff;
    outline: none;
    cursor: pointer;
    transition: 500ms;
    background: linear-gradient(120deg,#69ABED, #EA7A55);
    background-size: 200%;
}

.meme-submit:hover {
    background-position: right;
}
.meme-date-input input{
    height: 3vh;
}

.meme-image {
    height: 30px;
}

@media only screen and (max-width: 768px) {
    .meme-form {
        width: 80vw;
        height: 85vh;
        text-align: center;
    } 

    .meme-builder {
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 25px;
    }

    .meme-desc-input textarea {
        margin-top: 0;
        width: 60vw;
        height: 30vh;
    }

}

@media only screen and (max-height: 850px) {
    .meme-form {
        width: 80vw;
        height: 85vh;
        text-align: center;
    } 

    .meme-builder {
        height: 100vh;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 25px;
    }

    .meme-submit { 
        margin-top: 2px;
    }

    .meme-desc-input textarea {
        margin-top: 0;
        width: 60vw;
        height: 30vh;
    }

}