.cards {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

 @media only screen and (max-width: 1028px) {
    .cards {
        flex-direction: column;
    }    
 }

 .loader {
    height: 100px;
    width: 100px;
}

.loader-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
}

.search-input {
    width: 50%;
    border-bottom: 2px solid #adadad;
    margin: 30px 0;
    display: flex;
    align-items: center;
}

.search-input input {
    border: none;
    background: none;
    width: 100%;
    outline: none;
    padding: 0 5px;
    color: #333;
    height: 40px;
}

.search-icon {
    min-width: 2vw;
    height: 5vh;
    margin: 0 1.5rem 1vh 0;
    color: var(--fa-primary);
}